import HTMLElementShim from './ce-shim'

const template = document.createElement('template')
template.innerHTML = `<div aria-controls="#idAriaControls"><slot name="trigger"></slot></div>
                          <div class="details" id="#idAriaControls"><slot></slot></div>`

class AccordionBehaviour extends HTMLElementShim {
  static get observedAttributes () { return ['open'] }

  get open () {
    return this.hasAttribute('open')
  }

  set open (val) {
    if (val) {
      this.setAttribute('open', 'true')
      this.handleResize()
    } else {
      this.handleResize()
      this.removeAttribute('open')
    }
  }

  constructor () {
    super()

    const randomId = Math.ceil(Math.random() * 10000000000)

    const shadowRoot = this.attachShadow({ mode: 'closed' })
    const style = document.createElement('style')
    style.textContent = `
                .details {
                    transition: max-height 350ms ease-in-out;
                    overflow: hidden;
                }

                .details.closed {
                    max-height: 0px !important;
                }
            `
    shadowRoot.appendChild(style)
    shadowRoot.appendChild(template.content.cloneNode(true))
    shadowRoot.innerHTML = shadowRoot.innerHTML.replace(new RegExp('#idAriaControls', 'g'), randomId)

    this.trigger = shadowRoot.querySelector('[aria-controls]')
    this.content = shadowRoot.getElementById(randomId)
    this.boundClickHandler = AccordionBehaviour.prototype.handleClick.bind(this)
    this.boundResizeHandler = AccordionBehaviour.prototype.handleResize.bind(this)
  }

  connectedCallback () {
    // Check if sessionStorage is blocked
    this.storageAvailable = this.checkIfSessionStorageAvailable()

    if (this.storageAvailable) {
      const pageReloaded = window.performance.getEntriesByType('navigation')
        .map((entry) => entry.type)
        .includes('reload')
      // Clear saved state on reload
      if (pageReloaded) {
        this.removeStateForCurrentAccordion()
        this.writeStorage()
      } else {
        // Check saved state when page is navigated to
        this.readStorage()
      }
    }

    this.trigger.addEventListener('click', this.boundClickHandler)
    addEventListener('resize', this.boundResizeHandler)
    this.applyState()
  }

  disconnectedCallback () {
    this.trigger.removeEventListener('click', this.boundClickHandler)
    removeEventListener('resize', this.boundResizeHandler)
  }

  handleClick () {
    this.open = !this.open
    this.applyState(true)
  }

  handleResize () {
    this.content.style.maxHeight = this.content.scrollHeight + 'px'
  }

  applyState (shouldSaveState = false) {
    this.content.classList.toggle('closed', !this.open)
    this.trigger.setAttribute('aria-expanded', this.open.toString())
    this.content.firstChild.setAttribute('tabindex', this.open ? '0' : '-1') // content has a single slot child
    if (shouldSaveState) {
      this.writeStorage()
    }
  }

  // Read saved state from storage
  readStorage () {
    if (this.storageAvailable) {
      const index = [...document.querySelectorAll('hb-accordion')].indexOf(this)

      let state = sessionStorage.getItem('accordionState')

      // If state doesn't exist, save it
      if (state === null || !JSON.parse(state).hasOwnProperty(window.location.pathname) || JSON.parse(state)[window.location.pathname][index] === undefined) {
        // Save current value of this.open
        this.applyState(true)
      } else {
        // Set this.open to the stored value, and run applyState without re-saving
        this.open = JSON.parse(state)[window.location.pathname][index]
        this.applyState()
      }
    }
  }

  // Write current state to storage
  // Initialise state if needed
  writeStorage () {
    if (this.storageAvailable) {
      // Get index of accordion
      const index = [...document.querySelectorAll('hb-accordion')].indexOf(this)

      // Get accordionState from storage
      let state = sessionStorage.getItem('accordionState')

      if (state) {
        // Parse the JSON string
        state = JSON.parse(state)
      } else {
        // Initialise state if it doesn't exist
        state = {}
      }

      // If page doesn't exist in accordionState, create an entry
      if (!state.hasOwnProperty(window.location.pathname)) {
        state[window.location.pathname] = []
      }
      // Set status for current page
      state[window.location.pathname][index] = this.open
      sessionStorage.setItem('accordionState', JSON.stringify(state))
    }
  }

  // Check if sessionStorage is blocked
  checkIfSessionStorageAvailable () {
    try {
      const key = `__session__storage__test`
      sessionStorage.setItem(key, null)
      sessionStorage.removeItem(key)
      return true
    } catch (e) {
      return false
    }
  }

  // Clear the saved state for this accordion
  removeStateForCurrentAccordion () {
    if (this.storageAvailable) {
      // Get index of accordion
      const index = [...document.querySelectorAll('hb-accordion')].indexOf(this)

      let state = sessionStorage.getItem('accordionState')
      state = JSON.parse(state)
      if (state && state.hasOwnProperty(window.location.pathname)) {
        state[window.location.pathname][index] = undefined
        sessionStorage.setItem('accordionState', JSON.stringify(state))
      }
    }
  }
}

customElements.define('hb-accordion', AccordionBehaviour)
